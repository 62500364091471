(function() {
    var banner = new Swiper('#banner', { paginationClickable: true, pagination: '.pagination', autoplay: 4500, speed: 900, loop: true});

    // 产品解决方案
    initColumnSwiper('#meanRoll', { paginationClickable: true,initialSlide: 1, pagination: '.pagination', slidesPerView: 3, autoplay: 4500, speed: 600 });
    
    // 资讯滚动
    initColumnSwiper('#newsRoll', { paginationClickable: true, pagination: '.pagination', slidesPerGroup: 3, slidesPerView: 3, autoplay: 4500, speed: 600, loop: true })

    // 生活风格
    initColumnSwiper('#lifeRoll', { paginationClickable: true, pagination: '.pagination', slidesPerGroup: 3, slidesPerView: 3, autoplay: 4500, speed: 600, loop: true })

    // 系统特点
    initColumnSwiper('#oddsRoll', { paginationClickable: true, pagination: '.pagination', slidesPerGroup: 4, slidesPerView: 4, autoplay: 4500, speed: 600, loop: true })

    if (document.getElementById('opened')) {
        $('#opened').bind('click', function () {
            $('#drawer').toggleClass('active');
        })
    }

    if (document.getElementsByClassName) {
        var wow = new WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 0,
            live: true
        });
        wow.init();
    }

    if ($('.filter-item').length > 0) {
        $('.filter-item').on('click', 'dt', function() {
            var father = $(this).parents('.filter-item');
            father.toggleClass('filter-fold')
        })
        $('.filter-item').on('click', 'dd p', function () {
            var href = $(this).data('href');
            var text = $(this).text();
            var father = $(this).parents('.filter-item');
            var handler = father.find('dt span');

            father.removeClass('filter-fold');
            handler.text(text);
            if (href !== '') {
                window.location.href = href
            }
        })
    }

    if (document.getElementsByClassName('segment')) {
        $('.segment').segment()
    }
})()

