// 初始化特定效果的swiper
function initColumnSwiper(selector, option) {
    var space = option.slideSpace;
    var count = option.slidesPerView;
    var getCss = function name(elem, style) {
        var result = elem.currentStyle ? elem.currentStyle[style] : getComputedStyle(elem)[style]
        return parseFloat(result);
    }
    var params = {}
    for (var key in option) {
        params[key] = option[key]
    }
    params.dealMedia = function (ops, elem) {
        var hight = 0;
        var width = elem.offsetWidth;
        var wrap = elem.querySelector('.swiper-wrapper');
        var slide = elem.querySelectorAll('.swiper-slide');
        var items = elem.querySelector('.swiper-slide');
        var panel = items.querySelector('.card');
        space = space ? space : getCss(items, 'margin-right');
        setTimeout(function () { // 重新计算高度和边距
            hight = panel.offsetHeight + getCss(panel, 'margin-top') + getCss(panel, 'margin-bottom');
            for (var i = 0, lens = slide.length; i < lens; i++) {
                slide[i].style.height = Math.ceil(hight) + 'px';
                slide[i].style.marginRight = space + "px";
            }
            elem.style.height = Math.ceil(hight + 1) + 'px';
            wrap.style.height = Math.ceil(hight + 1) + 'px';
        }, 100)
        if (width > 768) {
            ops.slideSpace = space;
            ops.slidesPerView = count;
            ops.slidesPerGroup ? ops.slidesPerGroup = count : null;
        } else if (width > 480 && width <= 768) {
            ops.slideSpace = space;
            ops.slidesPerView = Math.max(count - 1, 1);
            ops.slidesPerGroup ? ops.slidesPerGroup = count - 1 : null;
        } else if (width <= 480) {
            ops.slideSpace = space;
            ops.slidesPerView = Math.max(count - 2, 1);
            ops.slidesPerGroup ? ops.slidesPerGroup = Math.max(count - 2, 1) : null;
        }
    }

    return new Swiper(selector, params);
}